<template>
	<div class="slide_verify_box" v-show="isVerificationShow">
		<div class="sv_box">
			<div class="puzzle-container">
				<div class="puzzle-header">
					<div class="puzzle-header-left">拖动下方滑块完成拼图</div>
					<div class="puzzle-header-right">
						<span class="re-btn iconfont" @click="refreshImg">&#xe627;</span>
						<span class="close-btn iconfont" @click="isVerificationShow=false">&#xe620;</span>
					</div>
					<div class="clear"></div>
				</div>
				<div :style="'position:relative;overflow:hidden;width:'+ dataWidth +'px;'">
					<div class="loading"><i class="iconfont">&#xe627;</i></div>
					<div :style="'position:relative;width:' + dataWidth + 'px;height:' + dataHeight + 'px;'">
						<img
							alt=""
							id="scream"
							ref="scream"
							:src="imgRandom"
							:style="'width:' + dataWidth + 'px;height:' + dataHeight + 'px;'"
						/>
					</div>
					<div class="puzzle-lost-box" :style="'left:' + left_Num + 'px;width:' + dataWidth + 'px;height:' + dataHeight + 'px;'">
						<div id="puzzle-shadow" ref="puzzleShadow" :width="dataWidth" :height="dataHeight">
							<img :src="imgSlide" alt="" />
						</div>
						<canvas id="puzzle-lost" ref="puzzleLost" :width="dataWidth" :height="dataHeight"></canvas>
					</div>
					<p :class="'ver-tips'+ (displayTips ? ' slider-tips' : '')" ref="verTips">
						<template v-if="verification">
							<i class="iconfont i1">&#xe754;</i>
							<span style="color:#10a53d;">验证通过</span>
							<span></span>
						</template>
						<template v-if="!verification">
							<i class="iconfont i2">&#xe620;</i>
							<span class="t-l">验证失败:</span>
							<span class="t-r">请拖动滑块将图像正确拼合</span>
						</template>
					</p>
				</div>

				<div class="slider-container" :style="'width:' + dataWidth + 'px;'">
					<div class="slider-bar"></div>
					<div class="slider-btn" ref="sliderBtn" @mousedown="startMove" @touchstart="startMove"><i class="iconfont">&#xe6ca;</i></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import API from "../api/config";
export default {
	name: "puzzleVerification",
	data() {
		return {
      		isVerificationShow: false,
			moveStart: "",
			displayTips: false,
			verification: false,
			randomX: null,
			randomY: null,
			imgRandom: "",
			imgSlide:"",
			left_Num: 0,
			dataWidth: null,
			dataHeight: null,
			puzzleSize: null, // 滑块的大小
			padding: null,
			verify_Y:0,
			CaptchaId:0
		};
  	},
	props: {
		// 手机号码
		Mobile: {
			type: String,
			default: ''
		},
		// 画布图片的尺寸
		width: {
			type: [String, Number],
			default: 350
		},
		height: {
			type: [String, Number],
			default: 180
		},
    	// 滑块的大小
		blockSize: {
			type: [String, Number],
			default: 40
   		 },
		// 成功的回调
		onSuccess: {
			type: Function,
			default: () => {
				// console.log("成功");
			}
		},
		// 失败的回调
		onError: {
			type: Function,
			default: () => {
				// console.log("失败");
			}
		},
	},
	created() {
		this.puzzleSize = Number(this.blockSize);
		this.dataWidth = Number(this.width);
		this.dataHeight = Number(this.height);
	},
	methods: {
		/* 刷新 */
		refreshImg() {
			// 获取滑块拼图验证信息
			API.Web.GetCaptcha({ TerminalType: 0,Mobile: this.Mobile,ImageType: 0}).then(res => {
                if(res.Code===0){
                    this.imgRandom = res.Data.Background;
					this.imgSlide = res.Data.Slide;
					this.verify_Y = res.Data.Y;
					this.CaptchaId = res.Data.CaptchaId;
                }
            });
		},
		/* 按住滑块后初始化移动监听，记录初始位置 */
		startMove(e) {
			// console.log(e);
			e = e || window.event;
			this.moveStart = e.pageX || e.targetTouches[0].pageX;
			this.addMouseMoveListener();
		},
		/* 滑块移动 */
		moving(e) {
			let that = this;
			e = e || window.event;
			let moveX = e.pageX || e.targetTouches[0].pageX;
			let d = moveX - that.moveStart;
			let w = that.dataWidth;
			let PL_Size = this.puzzleSize;
			if (that.moveStart === "") {
				return "";
			}
			if (d < 0 || d > w - PL_Size - 15) {
				return "";
			}
			that.$refs.sliderBtn.style.left = d + "px";
			that.$refs.sliderBtn.style.transition = "inherit";
			that.$refs.puzzleLost.style.left = d + "px";
			that.$refs.puzzleLost.style.transition = "inherit";
			that.$refs.puzzleShadow.style.left = d + "px";
			that.$refs.puzzleShadow.style.transition = "inherit";
		},
		/* 移动结束，验证并回调 */
		moveEnd(e) {
			let that = this;
			e = e || window.event;
			let moveEnd_X = (e.pageX || e.changedTouches[0].pageX) - that.moveStart;
			if (that.moveStart !== "") {
				let par = { TerminalType: 0,Mobile: this.Mobile,CaptchaId:this.CaptchaId,X:(moveEnd_X+this.left_Num),Y:this.verify_Y};
				// 验证滑块拼图
				API.Web.CheckCaptcha(par).then(res => {
					if(res.Code===0){
						if(res.Data){
							that.displayTips = true;
							that.verification = true;
							let st3 = setTimeout(function() {
								that.puzzlesliderback();
								clearTimeout(st3);
							}, 800);
							let st1 = setTimeout(function() {
								that.displayTips = false;
								/* 成功的回调函数 */
								that.onSuccess(par);
								clearTimeout(st1);
							}, 1200);
						}
						else{
							that.displayTips = true;
							that.verification = false;
							let st4 = setTimeout(function() {
								that.puzzlesliderback();
								clearTimeout(st4);
								that.refreshImg();
							},200);
							let st2 = setTimeout(function() {
								that.displayTips = false;
								/* 失败的回调函数 */
								that.onError();
								clearTimeout(st2);
							}, 800);
						}
						that.moveStart = "";
					}
				});
			}
		},
		/* 滑块回滚到初始位置 */
		puzzlesliderback(){
			this.$refs.sliderBtn.style.left = 0;
			this.$refs.sliderBtn.style.transition = "left 0.3s";
			this.$refs.puzzleLost.style.left = 0;
			this.$refs.puzzleLost.style.transition = "left 0.3s";
			this.$refs.puzzleShadow.style.left = 0;
			this.$refs.puzzleShadow.style.transition = "left 0.3s";
		},
		/* 全局绑定滑块移动与滑动结束，移动过程中鼠标可在页面任何位置 */
		addMouseMoveListener() {
			let that = this;
			document.addEventListener("mousemove", that.moving);
			document.addEventListener("touchmove", that.moving);
			document.addEventListener("mouseup", that.moveEnd);
			document.addEventListener("touchend", that.moveEnd);
		}
	},
};
</script>
<style lang="less" scoped>
.slide_verify_box {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: rgba(0,0,0,0.4);
    .sv_box{
        width: 400px;
        height: 285px;
        position: relative;
        left: calc(50% - 200px);
        top: calc(50% - 150px);
        background-color: #fcfcfc;
		border-radius: 10px;
		user-select:none;
		.puzzle-container {
			width: 350px;
			height: 260px;
			position: absolute;
			top: 5px;
			left: 25px;
			background: #ffffff;
			border-radius: 10px;
			.puzzle-header {
				width: 100%;
				height: 40px;
				line-height: 40px;
				overflow: hidden;
				.puzzle-header-left {
					color: #555;
					font-size: 14px;
					float: left;
				}
				.puzzle-header-right{
					float: right;
					.re-btn,.close-btn {
						cursor: pointer;
						color: #777;
						float: left;
						margin-left: 10px;
					}
					.re-btn:hover,.close-btn:hover{
						color: rgb(var(--themecolor));
					}
					.re-btn{
						font-size: 15px;
					}
					.close-btn{
						font-size: 18px;
					}
				}
			}
			.slider-container {
				position: relative;
				height: 50px;
				.slider-bar {
					height: 10px;
					border: 1px solid #dbdbdb;
					border-radius: 5px;
					background: #eee;
					position: absolute;
					width: 100%;
					top: 20px;
				}
				.slider-btn {
					position: absolute;
					width: 60px;
					height: 30px;
					left: 0;
					top: 11px;
					z-index: 12;
					cursor: pointer;
					background-color: rgba(var(--themecolor),0.9);
					box-shadow: 0px 0px 5px 0px #666;
					border-radius: 20px;
					text-align: center;
					line-height: 30px;
					i{
						font-size: 28px;
						color: #d1d1d1;
					}
				}
				.slider-btn:hover{
					background-color: rgba(var(--themecolor),1);
				}
			}
			.ver-tips {
				position: absolute;
				left: 0;
				bottom: -30px;
				z-index: 120;
				background: rgba(255, 255, 255, 0.9);
				height: 30px;
				line-height: 30px;
				font-size: 13px;
				width: 100%;
				margin: 0;
				text-align: left;
				padding: 0 8px;
				transition: all 0.2s;
				-webkit-transition: all 0.2s;
				i {
					width: 22px;
					height: 30px;
					font-size: 16px;
					float: left;
					margin-top: 1px;
				}
				i.i1{
					color: #07b42d;
				}
				i.i2{
					color: #f00606;
				}
				.t-l {
					line-height: 30px;
					color: #f00606;
				}
				.t-r {
					line-height: 30px;
					color: #666;
					margin-left: 5px;
				}
			}
			.slider-tips {
				bottom: 0;
			}
		}
    }
}
#puzzle-box {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 22;
}
#puzzle-shadow {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 33;
}
#puzzle-lost {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 33;
}
.puzzle-lost-box {
	position: absolute;
	// width: 260px;
	// height: 116px;
	left: 0;
	top: 0;
	z-index: 10;
}
.loading{
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #fff;
	color: #bebdbd;
	font-size: 50px;
	text-align: center;
	line-height: 175px;
	animation:loadiing 1s linear 0s infinite;
    -webkit-animation:loadiing 1s linear 0s infinite;
}
@-webkit-keyframes loadiing {
from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes loadiing {
from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
</style>